import Hero from "../hero/Hero"
import React from "react"

export default props => (
  <div className="no-img-hero cr-banner">
    <p class="mobile-cr-banner navy-blue">Prior MI Subgroup Data</p>
    <Hero className={"vascepa-gray-hero"}>
      <h1 className="headline">
        <p className="subheadline">
          <strong className="bold700">
            In a prespecified and <em>post-hoc</em> analysis
          </strong>{" "}
          of adults on maximally tolerated statins with TG ≥150 mg/dL and prior
          MI<sup>1,2</sup>
        </p>
        <span>
        Cardiovascular risk reduction seen in patients with prior MI in REDUCE-IT<sup>&reg;</sup>{" "}
          subgroup analysis<sup>2</sup>
        </span>
      </h1>
    </Hero>
  </div>
)
