import React, { Component } from "react"
import Windowshade from "../windowshade/Windowshade"
import Collapsible from "../collapsible/Collapsible"

/* mobile charts */
import Composite_Primary_percent from "../../images/graphs/mobile/Composite_Primary_percent.png"
import Composite_Primary_mobile from "../../images/graphs/mobile/Composite_Primary_mobile.png"
import Composite_Secondary_mobile from "../../images/graphs/mobile/Composite_Secondary_mobile.png"
import Composite_Secondary_percent from "../../images/graphs/mobile/Composite_Secondary_percent.png"
import Reduction_Primary_mobile from "../../images/graphs/mobile/Reduction_Primary_mobile.png"
import Reduction_Primary_percent from "../../images/graphs/mobile/Reduction_Primary_percent.png"

/* desktop charts */
import Composite_Primary from "../../images/graphs/desktop/desktop_26.png"
import Composite_Secondary from "../../images/graphs/desktop/desktop_29.png"
import Reduction_Primary from "../../images/graphs/desktop/desktop_35.png"

import Magnify_icon from "../../images/magnify-icon.svg"

import { Link } from "gatsby"
import "./styles.css"

const CollapseMobile = props => {
  return (
    <Collapsible
      title={[
        "VASCEPA CV Outcomes Trial",
        <br key="2" />,
        "(REDUCE-IT",
        <sup key="1" className="sup-align-top-2">
          ®
        </sup>,
        ") Design",
        <sup key="3">1</sup>,
      ]}
      collapsibleWidth={"collapsible512 m-center"}
    >
      <p className="body-copy">
        A randomized, double-blind, placebo-controlled trial for adults with
        eCVD or with diabetes and other risk factors, receiving statin therapy,
        and with modestly elevated TG (median baseline 216 mg/dL) and
        well-controlled LDL-C (median baseline 75 mg/dL). 8179 patients (VASCEPA
        4 g/d [n=4089]; placebo [n=4090]) were followed for a median of 4.9
        years.
      </p>
      <p style={{ paddingTop: "20px" }} className="body-copy">
        Patients in the VASCEPA CV Outcomes Trial (REDUCE-IT) received
        standard-of-care medications including statins (100%), antihypertensives
        (95%), antiplatelet medication (79%), ACE inhibitors (52%), ARBs (27%),
        and beta blockers (71%).
      </p>
    </Collapsible>
  )
}

const CollapseDesk = props => {
  return (
    <Collapsible
      title={[
        "VASCEPA CV Outcomes Trial (REDUCE-IT",
        <sup key="1" className="sup-align-top-2">
          ®
        </sup>,
        ") Design",
        <sup key="3">1</sup>,
      ]}
      collapsibleWidth={"collapsible512 m-center"}
    >
      <p className="body-copy">
        A randomized, double-blind, placebo-controlled trial for adults with
        eCVD or with diabetes and other risk factors, receiving statin therapy,
        and with modestly elevated TG (median baseline 216 mg/dL) and
        well-controlled LDL-C (median baseline 75 mg/dL). 8179 patients (VASCEPA
        4 g/d [n=4089]; placebo [n=4090]) were followed for a median of 4.9
        years.
      </p>
      <p style={{ paddingTop: "20px" }} className="body-copy">
        Patients in the VASCEPA CV Outcomes Trial (REDUCE-IT) received
        standard-of-care medications including statins (100%), antihypertensives
        (95%), antiplatelet medication (79%), ACE inhibitors (52%), ARBs (27%),
        and beta blockers (71%).
      </p>
    </Collapsible>
  )
}

export default props => {
  function goModal(evt) {
    const dataAtt = evt.target.dataset.modal
    const dataHref = evt.target.dataset.href || null
    props.goModal(dataAtt, dataHref)
  }

  // conditional render see components above
  const isMobile = props.isMobile
  let collapse
  if (isMobile) {
    collapse = <CollapseMobile />
  } else {
    collapse = <CollapseDesk />
  }

  return (
    <main className="main">
      <section id="section-1" className="endpoint-outcome-sec-one cr-data">
        <h2 className="cv-headline d-m-l-0">
          VASCEPA significantly{" "}
          <strong className="bold700">
            reduced the risk of the next CV event
          </strong>
          * in patients with prior MI by 26%<sup>2</sup>
        </h2>
        <div className="windowshade-container">
          <div className="windowshade-header">
            <p className="">See Graph</p>
          </div>
          <Windowshade id={"first_coronary_revascularization"}>
            <div className="shade-copy-container">
              <p className="shade-body-copy">26% RRR</p>
              <p className="shade-body-copy-alt">
                ARR=5.9%; <em>P</em>=0.00001
              </p>
            </div>
            <div className="windowshade-chartheadline-container">
              <h3 className="windowshade-chartheadline magenta">
                Composite first occurrence of 5-point MACE{" "}
                <span className="nowrap">(primary endpoint)</span>
              </h3>
              <img
                className="magnify_icon"
                data-modal="composite_primary"
                src={Magnify_icon}
                onClick={goModal}
                alt=""
              />
            </div>
            <img className="chart" src={Composite_Primary_mobile} alt="" />

            <div className="windowshade-footer">
              <p className="">close</p>
            </div>
          </Windowshade>
        </div>
        <div className="chart-container-centering d-m-l-0">
          <div className="chart-container">
            <h4 className="cv-chartheadline magenta mobile-text-center">
              Composite first occurrence of 5-point MACE{" "}
              <span className="nowrap">(primary endpoint)</span>
            </h4>
            <div className="chartlockup-outcomes">
              <img
                className="chart m-chart-center mobile-w-160px m-b-10"
                src={
                  props.isMobile ? Composite_Primary_percent : Composite_Primary
                }
                alt=""
              />
              {props.isMobile ? null : (
                <img
                  className="magnify_icon"
                  data-modal="composite_primary"
                  src={Magnify_icon}
                  onClick={goModal}
                  alt=""
                />
              )}
            </div>

            <p className="cv-chart-footnote">
              Adapted from the{" "}
              <em>Journal of the American College of Cardiology</em>, Vol 79/17,
              Gaba P, Bhatt DL, Steg PG, et al. Prevention of ischemic events
              and mortality with icosapent ethyl in patients with prior
              myocardial infarction, pp 1660-1671. Copyright 2022, with
              permission from Elsevier.
            </p>
            <p className="cv-chart-footnote">
              At Year 5 since randomization, 1430 patients remained in the
              VASCEPA arm vs 1358 patients in the placebo arm in the overall
              trial population; 698 patients remained in the VASCEPA arm vs 677
              patients in the placebo arm in the prespecified prior MI
              subpopulation.
            </p>
          </div>

          <div className="chart-sidebar">
            <ul className="content-list">
              <li className="primary-list-item">
                VASCEPA reduced the incidence of the primary composite endpoint
                of Nonfatal MI, Nonfatal Stroke, CV Death, Coronary
                Revascularization, or Unstable Angina Requiring Hospitalization
                from 26.1% to 20.2% vs placebo (HR=0.74 [95% CI, 0.65-0.85];{" "}
                <em>P</em>=0.00001)
              </li>
            </ul>
          </div>
        </div>

        <div className="outcomes-callout d-m-l-0 m-t-20 m-b-35 mobile-m-b-0">
          <p className="body-copy">
            REDUCE-IT was not specifically powered to examine individual
            cardiovascular endpoints or patient subgroups, therefore{" "}
            <span class="nowrap">
              <em>P</em>-values
            </span>{" "}
            are nominal and exploratory with no adjustment for multiple
            comparisons. In addition, enrollment was not stratified by prior MI
            or time since MI, and patients were not enrolled at the time of
            their MI. However, significant reductions were demonstrated for
            prior MI patients treated with icosapent ethyl, in the primary
            endpoint, the key secondary endpoint, and other secondary and
            tertiary endpoints. Together, these REDUCE-IT sub-analyses support
            the robustness and consistency of the clinical benefit of icosapent
            ethyl therapy in patients with prior MI.
          </p>
        </div>
      </section>
      <hr />

      <section
        id="section-2"
        className="endpoints endpoints-section-last no-bottom-border m-b-45 mobile-m-b-0"
      >
        <h2 className="cv-headline">
          VASCEPA significantly{" "}
          <span className="bold700">
            reduced the risk of MI, stroke, or CV death
          </span>{" "}
          in patients with prior MI by 29%<sup>2</sup>
        </h2>

        <div className="chart-container-centering m-flex">
          <div className="chart-container">
            <div className="windowshade-container">
              <div className="windowshade-header">
                <p className="">See Graph</p>
              </div>
              <Windowshade id={"Time_to_PCI"}>
                <div className="shade-copy-container">
                  <p className="shade-body-copy">29% RRR</p>
                  <p className="shade-body-copy-alt">
                    ARR=4.7%; <em>P</em>=0.00006
                  </p>
                </div>
                <div className="windowshade-chartheadline-container">
                  <h3 className="windowshade-chartheadline magenta">
                    Composite first occurrence of 3-point MACE (key{" "}
                    <span className="nowrap">secondary endpoint)</span>
                  </h3>
                  <img
                    className="magnify_icon"
                    data-modal="Composite_Secondary"
                    src={Magnify_icon}
                    onClick={goModal}
                    alt=""
                  />
                </div>
                <img
                  className="chart"
                  src={Composite_Secondary_mobile}
                  alt=""
                />

                <div className="windowshade-footer">
                  <p className="">close</p>
                </div>
              </Windowshade>
            </div>
            <div id="Time_to_PCI_under" className="windowshade-under">
              <h4 className="cv-chartheadline magenta mobile-text-center">
                Composite first occurrence of 3-point MACE (key{" "}
                <span className="nowrap">secondary endpoint)</span>
              </h4>
              <div className="chartlockup">
                <img
                  className="chart mobile-align-center mobile-w-160px"
                  src={
                    props.isMobile
                      ? Composite_Secondary_percent
                      : Composite_Secondary
                  }
                  alt=""
                />
                <img
                  className="magnify_icon"
                  data-modal="composite_secondary"
                  src={Magnify_icon}
                  onClick={goModal}
                  alt=""
                />
              </div>
              <p className="cv-chart-footnote">
                Adapted from the{" "}
                <em>Journal of the American College of Cardiology</em>, Vol{" "}
                79/17, Gaba P, Bhatt DL, Steg PG, et al. Prevention of ischemic
                events and mortality with icosapent ethyl in patients with prior
                myocardial infarction, pp 1660-1671. Copyright 2022, with
                permission from Elsevier.
              </p>
              <p className="cv-chart-footnote">
                At Year 5 since randomization, 1562 patients remained in the
                VASCEPA arm vs 1487 in the placebo arm in the overall trial
                population for the key secondary outcome; 698 patients remained
                in the VASCEPA arm vs 678 patients in the placebo arm in the
                prespecified prior MI subpopulation.
              </p>
            </div>
          </div>

          <div className="chart-sidebar">
            <ul className="content-list mobile-m-b-0">
              <li className="primary-list-item  mobile-m-b-0">
                VASCEPA reduced the incidence of the key secondary composite
                endpoint of Nonfatal MI, Nonfatal Stroke, or CV Death from 18.0%
                to 13.3% vs placebo (HR=0.71 [95% CI, 0.61-0.84]; <em>P</em>
                =0.00006)
              </li>
            </ul>
          </div>
        </div>
      </section>
      <hr />
      <section
        id="section-3"
        className="endpoints endpoints-section-last no-bottom-border"
      >
        <h2 className="cv-headline">
          VASCEPA, when added to a statin, significantly{" "}
          <strong className="bold700">reduced total CV events</strong> among
          patients with prior MI<sup>2</sup>
        </h2>

        <div className="chart-container-centering m-flex">
          <div className="chart-container m-order-2">
            <div className="windowshade-container">
              <div className="windowshade-header">
                <p className="">See Graph</p>
              </div>
              <Windowshade id={"Time_to_CABG"}>
                <div className="shade-copy-container">
                  <p className="shade-body-copy">35% RRR</p>
                  <p className="shade-body-copy-alt">
                    <em>P</em>=0.0000001
                  </p>
                </div>
                <div className="windowshade-chartheadline-container">
                  <h3 className="windowshade-chartheadline magenta">
                    Reductions in total primary composite{" "}
                    <span className="nowrap">endpoint events</span>
                  </h3>
                  <img
                    className="magnify_icon"
                    data-modal="Reduction_Primary"
                    src={Magnify_icon}
                    onClick={goModal}
                    alt=""
                  />
                </div>
                <img className="chart" src={Reduction_Primary_mobile} alt="" />

                <div className="windowshade-footer">
                  <p className="">close</p>
                </div>
              </Windowshade>
            </div>
            <div className="windowshade-under">
              <h4
                className="cv-chartheadline magenta mobile-text-center"
                style={{ marginTop: "30px" }}
              >
                Reductions in total primary composite{" "}
                <span className="nowrap">endpoint events</span>
              </h4>
              <div className="chartlockup">
                <img
                  className="chart mobile-align-center mobile-w-160px"
                  src={
                    props.isMobile
                      ? Reduction_Primary_percent
                      : Reduction_Primary
                  }
                  alt=""
                />
                <img
                  className="magnify_icon"
                  data-modal="Reduction_Primary"
                  src={Magnify_icon}
                  onClick={goModal}
                  alt=""
                />
              </div>
              <p className="cv-chart-footnote">
                Adapted from the{" "}
                <em>Journal of the American College of Cardiology</em>, Vol{" "}
                79/17, Gaba P, Bhatt DL, Steg PG, et al. Prevention of ischemic
                events and mortality with icosapent ethyl in patients with prior
                myocardial infarction, pp 1660-1671. Copyright 2022, with
                permission from Elsevier.
              </p>
              <p className="cv-chart-footnote">
                At Year 5 since randomization, 1430 patients remained in the
                VASCEPA arm vs 1358 patients in the placebo arm in the overall
                trial population; 698 patients remained in the VASCEPA arm vs
                677 patients in the placebo arm in the prespecified prior MI
                subpopulation.
              </p>
            </div>
          </div>

          <div className="chart-sidebar m-order-1"></div>
        </div>

        <div className="outcomes-callout cr-callout">
          <div className="cta-container m-center fit-content">
            <h3 className="cta-copy">Get patients started on VASCEPA</h3>
            <Link to="/get-vascepa-now/" className="cta-btn">
              Learn more
            </Link>
          </div>
        </div>
      </section>
    </main> /* end main*/
  )
}
