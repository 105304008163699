import React, { Component } from "react"
import { Helmet } from "react-helmet"

import Header from "../components/header/Header"
// import HeroImage from "../components/1.6_prior_mi_subgroup_data/HeroImage"
// import SideNavigation from "../components/1.6_prior_mi_subgroup_data/SideNavigation"
// import Main from "../components/1.6_prior_mi_subgroup_data/Main"

import HeroImage from "../components/1.6_prior_mi_subgroup_data/HeroImage"
import SideNavigation from "../components/1.6_prior_mi_subgroup_data/SideNavigation"
import Main from "../components/1.6_prior_mi_subgroup_data/Main"


import ISI from "../components/isi/ISI"
import Footer from "../components/footer/Footer"
import Modal from "../components/modal/Modal"
import HomeModal from "../components/modal/HomeModal"

import { Waypoint } from "react-waypoint"
import SEO from "../components/seo"

import Loader from "../components/loader"

/* styles */
import "../components/1.0_cv_outcomes/cv_outcomes.css"

export default class OData extends Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
      isMobile: false,
      modal: null,
      hideNav: false,
    }
  }

  componentDidMount() {
    let bool = window.matchMedia("only screen and (max-width: 1023px)").matches
    this.setState({ isMobile: bool })
  }

  goModal = (modal, href) => {
    this.setState(() => ({
      modal: modal,
      href: href,
    }))
  }

  modalClosed = () => {
    this.setState(() => ({ modal: null, href: null }))
  }

  handleWaypointEnter = () => {
    this.setState(() => ({ hideNav: false }))
  }

  handleWaypointLeave = () => {
    this.setState(() => ({ hideNav: true }))
  }

  handleFooterEnter = () => {
    // this.setState(() => ({ hideNav: false }))
  }

  handleFooterLeave = () => {
    // this.setState(() => ({ hideNav: true }))
  }

  render() {
    return (
      <>
        <Loader>
          <SEO
            title="Coronary Revascularization Events"
            keywords={[""]}
            description="VASCEPA reduced the need for coronary revascularizations across first and total events."
          />
          <HomeModal />
          <Modal
            modal={this.state.modal}
            modalClosed={this.modalClosed}
            href={this.state.href}
          />
          <Header
            isMobile={this.state.isMobile}
            hideNav={this.state.hideNav}
            goModal={this.goModal}
          />
          <div className="grad"></div>
          <HeroImage bckgrdImage={"header1-2"} />
          <Waypoint
            onEnter={this.handleWaypointEnter}
            onLeave={this.handleWaypointLeave}
          />
          <div className="wrapper">
            <div className="grid">
              <SideNavigation stickyNav={this.state.hideNav} />
              <Main isMobile={this.state.isMobile} goModal={this.goModal} />
            </div>
          </div>
          <ISI />
          <div className="ref-outer">
          <div className="footnotes-container">
            <div className="footnotes">
              <p className="cv-footnote">
              ARR=absolute risk reduction; CI=confidence interval; CV=cardiovascular; CVD=cardiovascular disease; HR=hazard ratio; MACE=major adverse cardiovascular events; MI=myocardial infarction; NNT=number needed to treat; RRR=relative risk reduction; TG=triglyceride.
            </p>
              <p className="cv-footnote hang">
            *Cardiovascular events including myocardial infarction, stroke, coronary revascularization, and unstable angina requiring hospitalization.
            </p>
            </div>
          </div>
            <div className="footnotes-container">
              <div className="references">
                <p className="cv-footnote">
                  <strong>References: 1.</strong> VASCEPA [package insert]. Bridgewater, NJ: Amarin Pharma, Inc.; 2021.
                  <strong> 2. </strong>Gaba P, Bhatt DL, Steg PG, et al; on behalf of the REDUCE-IT Investigators. Prevention of cardiovascular events and mortality with icosapent ethyl in patients with prior myocardial infarction. <em>J Am Coll Cardiol</em>. 2022;79(17):1660-1671. 
                </p>
              </div>
            </div>
          </div>
          <Waypoint
            onEnter={this.handleFooterEnter}
            onLeave={this.handleFooterLeave}
          />
          <Footer />
        </Loader>
      </>
    )
  }
}
